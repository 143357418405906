import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React from 'react'
import Layout from "../components/layout"
import { Container, Jumbotron, Button } from 'reactstrap'

const confirmation = () => {
    return (
        <Layout>

            <Container
                style={{
                    padding: '2rem 2rem 1rem',
                    minHeight: '75vh'
                }}
                >
                <Jumbotron >
                    <h2 className="display-4">
                        Thank you for registering!
                    </h2>
                    <p className="lead">
                        Your account has been created and an activation link has been sent to the email address you have entered. Note that you must activate the account by clicking on the activation link. <br />
                        (Activation link expires after 24 hours)
                    </p>
                    <p className="lead">
                        <Button
                            color="primary"
                            onClick={event => {
                                event.preventDefault()
                                window.location.replace('/')
                            }}
                        >
                            Go back home
                        </Button>
                    </p>

                </Jumbotron>
            </Container>
        </Layout>
    )
}

export default confirmation
